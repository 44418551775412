<template>
  <div class="animated fadeIn">
    <b-card>
      <b-row>
        <ResponseAlert ref="response" />
        <div class="col-sm-12 d-flex justify-content-between align-items-center flex-column flex-md-row">
          <div class="col-md-6 col-12 px-0 order-1 order-md-0 d-flex flex-column flex-md-row">
            <div
              class="input-group col-lg-7 col-md-8 px-0"
            >
              <input
                v-model="config.search"
                type="text"
                placeholder="Cari kata kunci"
                class="form-control"
                @keyup.enter="config.page = 1; get()"
              >
              <div class="input-group-append">
                <div
                  class="input-group-text"
                  @click="config.page = 1; get()"
                >
                  <feather-icon icon="SearchIcon" />
                </div>
              </div>
            </div>
          </div>
          <div class="order-0 order-md-1 mb-1 mb-md-0 full-width-responsive">
            <b-button
              v-if="hasPermission('filter/purchase-order')"
              v-b-modal.modal-filter-invoice
              variant="secondary"
              class="btn-min-width rounded full-width-responsive"
            >
              Advance Filter
            </b-button>
          </div>
        </div>
        <b-col
          sm="12"
          class="mt-2 overflow-auto"
        >
          <b-button-group>
            <b-button
              v-for="status, idx in masters.status"
              :key="idx"
              :variant="statusParams === status.value ? 'primary' : 'link'"
              @click="config.additional_params.status = status.value"
            >
              {{ status.text }}
            </b-button>
          </b-button-group>
        </b-col>
        <b-col
          sm="12"
          class="mt-1"
        >
          <div class="table-responsive">
            <table class="table b-table">
              <thead>
                <tr>
                  <th
                    v-for="(row, key) in config.headers"
                    :key="key"
                    :width="row.width"
                    :class="row.class"
                  >
                    <a
                      v-if="row.value"
                      class="sort dark-font"
                      href="javascript:void(0)"
                      @click="sort(row.value)"
                    >
                      {{ row.title }}
                      <i
                        :id="row.value"
                        class="fa fa-sort"
                      />
                    </a>
                    <a
                      v-else
                      :id="row.value"
                      class="sort dark-font"
                      href="javascript:void(0)"
                    >
                      {{ row.title }}
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="config.total_data">
                  <tr
                    v-for="(row, key) in config.rows"
                    :key="key"
                  >
                    <td>{{ moment(row.changeInvoiceDate).format('DD-MM-YYYY') }}</td>
                    <td>{{ row.sales.noOrder }}</td>
                    <td>{{ row.kfs.name }}</td>
                    <td v-if="row.sales.staff">
                      {{ row.sales.staff.profile.firstName }} {{ row.sales.staff.profile.lastName }}
                    </td>
                    <td v-else>
                      -
                    </td>
                    <td>
                      Rp. {{ parseFloat(row.totalPayment).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}
                    </td>
                    <td>{{ row.changeInvoiceStatus === 1 ? 'Disetujui' : row.changeInvoiceStatus === -1 ? 'Ditolak' : 'Menunggu Persetujuan' }}</td>
                    <td align="center">
                      <div class="d-flex justify-content-center">
                        <custom-button
                          :url="`/${config.uri}/${row.id}`"
                          permission="detail/invoice"
                          title="Detail"
                          class-name="btn-action mr-1"
                        >
                          <feather-icon icon="EyeIcon" />
                        </custom-button>
                        <button
                          v-if="row.changeInvoiceStatus === 0"
                          title="Approve"
                          class="btn-action bg-transparent border-0 text-primary p-0"
                          @click="detailInvoice = row; $bvModal.show('modal-confirm-invoice')"
                        >
                          <feather-icon icon="CheckCircleIcon" />
                        </button>
                      </div>
                    </td>
                  </tr>
                </template>
                <tr v-if="!config.total_data">
                  <td
                    :colspan="config.headers.length"
                    align="center"
                  >
                    No data available.
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="config.total_data">
                <tr>
                  <td
                    :colspan="config.headers.length"
                    class="p-0"
                  >
                    <div class="d-flex justify-content-between mt-2">
                      <b-form-select
                        v-model="config.per_page"
                        :options="perPageOptions"
                        class="w-auto"
                      />
                      <b-pagination
                        v-model="config.page"
                        :total-rows="config.total_data"
                        :per-page="config.per_page"
                        @change="gotoPage"
                      />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- Modal Advance Search -->
    <b-modal
      id="modal-filter-invoice"
      ref="modal-filter-invoice"
      centered
      title="Filter"
      hide-footer
      no-close-on-backdrop
    >
      <form
        @submit.prevent="advanceSearch"
      >
        <div class="animated fadeIn">
          <b-row
            class="my-1"
            align-v="center"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="date"
              >Tanggal</label>
            </b-col>
            <b-col sm="7">
              <date-range-picker
                :date-range="vars.registrationDate"
                @update="value => updateDate('createdAt', value)"
              />
            </b-col>
          </b-row>
          <b-row
            class="my-1"
            align-v="center"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="target"
              >KFS</label>
            </b-col>
            <b-col sm="7">
              <v-select
                id="tag"
                v-model="models.kfsId"
                clearable
                label="name"
                placeholder="- Pilih Pengguna -"
                :options="masters.kfs"
                :reduce="options => options.id"
                @search="value => debounceSearch(value, 'kfs')"
              >
                <template #no-options="{ search, searching, loading }">
                  Tidak ditemukan hasil pencarian
                </template>
              </v-select>
            </b-col>
          </b-row>
          <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end mt-2">

            <b-button
              type="button"
              variant="outline-secondary"
              class="btn-min-width rounded"
              @click="exportExcel"
            >
              Export Data
            </b-button>
            <b-button
              type="button"
              variant="outline-primary"
              class="btn-min-width rounded mx-0 mx-md-1 my-1 my-md-0"
              @click="advanceSearch('reset')"
            >
              Reset
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              class="btn-min-width rounded"
            >
              Simpan
            </b-button>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- Modal Penyetujuan Invoice -->
    <b-modal
      id="modal-confirm-invoice"
      ref="modal-confirm-invoice"
      centered
      hide-footer
    >
      <form class="py-3">
        <div class="animated fadeIn">
          <h5 class="text-center">
            Penyetujuan Ubah Invoice
          </h5>
          <div class="d-flex flex-column flex-md-row justify-content-center mt-4">
            <b-button
              type="submit"
              variant="primary"
              class="btn-min-width rounded px-3"
              @click.prevent="acceptPO(detailInvoice)"
            >
              Setujui
            </b-button>
            <b-button
              type="button"
              variant="outline-primary"
              class="btn-min-width rounded mx-0 mx-md-1 my-1 my-md-0 px-3"
              @click.prevent="rejectPO(detailInvoice.id)"
            >
              Tolak
            </b-button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import DateRangePicker from '@/layouts/components/DateRangePicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { exportExcel } from '@/utils/helpers'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BPagination, BFormSelect, BButton, BButtonGroup,
} from 'bootstrap-vue'
// import table from '@/utils/table.js'
import api from '@/utils/api'

export default {
  name: 'ListInvoice',
  metaInfo: {
    title: 'List Invoice',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BButton,
    BButtonGroup,
    vSelect,
    DateRangePicker,
  },
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: `${api.invoice}/change`,
        rows: [],
        additional_params: {
          status: null,
          kfsId: null,
          date: null,
        },
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'Tanggal',
          value: '',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Nomor Order',
          value: '',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'KFS',
          value: '',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Staff',
          value: '',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Total Pembayaran',
          value: '',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Status',
          value: '',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Actions',
          value: '',
          class: 'text-center',
          width: '10%',
        }],
      },
      masters: {
        kfs: [],
        status: [
          {
            text: 'Semua',
            value: null,
          },
          {
            text: 'Pengajuan',
            value: 0,
          },
          {
            text: 'Disetujui',
            value: 1,
          },
          {
            text: 'Ditolak',
            value: -1,
          },
        ],
      },
      models: {
        // filter
        date: null,
        kfsId: '',
      },
      vars: {
        registrationDate: {
          startDate: null,
          endDate: null,
        },
      },
    }
  },
  computed: {
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
    statusParams() {
      return this.config.additional_params.status
    },
  },
  watch: {
    perPage() {
      this.config.page = 1
      this.get()
    },
    statusParams() {
      this.config.page = 1
      this.get()
    },
  },
  created() {
    const _ = this
    _.get()
    _.getListOption()
  },
  methods: {
    get() {
      this.$table.get(this.config)
    },
    sort(orderby) {
      if (orderby !== '') this.$table.sort(this.config, orderby)
    },
    gotoPage(page) {
      this.$table.gotoPage(this.config, page)
    },
    getListOption() {
      this.$axios.get(`${api.list_kfs}`).then(res => {
        this.masters.kfs = [...res.data.data]
      })
    },
    debounceSearch(value) {
      if (value !== '') {
        this.search.kfs = value
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          const response = await this.handleSearchTag()
          this.masters.kfs = [...response]
        }, 500)
      }
    },
    async handleSearchTag() {
      const res = await this.$axios.get(`${api.list_kfs}?search=${this.search.kfs}`)
      return res.data.data.rows
    },
    advanceSearch(value) {
      if (value === 'reset') {
        this.config.additional_params.kfsId = this.models.kfsId = null
        this.config.additional_params.date = this.models.date = null
      } else {
        if (this.models.kfsId) this.config.additional_params.kfsId = this.models.kfsId
        if (this.models.date) this.config.additional_params.date = this.models.date
      }
      this.config.page = 1
      this.get()
      this.$bvModal.hide('modal-filter-invoice')
    },
    exportExcel() {
      exportExcel('List Invoice Sales', `${api.invoice}/export`)
    },
    acceptPO(data) {
      this.$axios.post(`${api.invoice}/change/approve`, {
        id: data.id,
        payload: {
          id: data.id,
          kfsId: data.kfsId,
          salesId: data.salesId,
          invoiceCode: data.invoiceCode,
          totalInvoice: data.totalInvoice,
          voucherCode: data.voucherCode,
          discount: data.discount,
          totalPayment: data.totalPayment,
          paymentMethod: data.paymentMethod,
          cashpay: data.cashpay,
          change: data.change,
          debitNumber: data.debitNumber,
          phoneNumber: data.phoneNumber,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success approve invoice',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.get()
          this.$bvModal.hide('modal-confirm-invoice')
          window.scrollTo(0, 0)
        })
        .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
    },
    rejectPO(id) {
      this.$swal({
        title: 'Perhatian',
        text: 'Apakah anda yakin akan menolak permintaan ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.post(`${api.invoice}/change/reject`, {
            id,
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success declined invoice',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('modal-confirm-invoice')
              this.get()
              window.scrollTo(0, 0)
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        }
      })
    },
    updateDate(value) {
      const { startDate, endDate } = value
      this.models.date = `${this.moment(startDate).format('YYYY-MM-DD')},${this.moment(endDate).format('YYYY-MM-DD')}`
    },
  },
}
</script>
